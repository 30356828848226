import React from "react";
import Layout from "../../components/layout/layout";
import formQuestions from "../../data/questions/exampleFormQuestions";
import Stepper from "../../components/register/registerPayStepper";
import "../../styles/styles.scss";

export default (props) => {
  return (
    <Layout pageTitle="Payment Success">
      <div style={{ height: "15vh" }}></div>
      <Stepper
        formQuestions={formQuestions}
        formTitle={""}
        formType={""}
        formOpts={""}
        currentStepQS={2}
      />
    </Layout>
  );
};
